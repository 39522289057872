<template>
  <div>
    <validation-observer ref="question">
      <b-form>
        <b-row>
          <b-col cols="12" md="12">
            <b-card no-body>
              <b-card-header>
                <h4 class="card-title">{{i18nT(`Question`)}}</h4>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="onEdit()">
                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSave()">
                    <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="hasRouteId" @click="onDelete()">
                    <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`)}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-card-body>
                <b-row class="border-bottom pb-2">
                  <!-- Field: Username -->
                  <b-col cols="12" md="3">
                    <h5 class="mt-2">{{i18nT(`Question details`)}}</h5>
                  </b-col>
                  <b-col cols="12" md="9">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group :label="i18nT(`Type`)" label-for="questionType">
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Type`)"
                            rules="required"
                          >
                            <v-select
                              v-model="questionType"
                              :options="questionTypes"
                              label="text"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group :label="i18nT(`Title`)" class="required" label-for="questionLabel">
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Title`)"
                            rules="required"
                          >
                            <b-form-input
                              v-model="questionLabel"
                              :placeholder="i18nT(`E.g. Question Name`)"
                              name="questionLabel"
                              id="questionLabel"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="questionDescription"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Description`)"
                            rules="required"
                          >
                            <b-form-textarea
                              id="questionDescription"
                              rows="4"
                              v-model="questionDescription"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="border-bottom py-2">
                  <!-- Field: Username -->
                  <b-col cols="12" md="3">
                    <h5 class="mt-2">{{i18nT(`Question design`)}}</h5>
                  </b-col>
                  <b-col cols="12" md="9" class="pt-2">
                    <b-row v-if="questionType.value === '1'">
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Free text answer filed`)"
                          label-for="freeText"
                        >
                          <div class="demo-inline-spacing">
                            <b-form-radio
                              v-model="linesCnt"
                              name="linesCnt"
                              value="1"
                              class="mt-0"
                            >
                            {{ i18nT(`Single line`) }}
                            </b-form-radio>
                            <b-form-radio
                              v-model="linesCnt"
                              name="linesCnt"
                              value="99"
                              class="mt-0"
                            >
                            {{ i18nT(`Multi-line`) }}
                            </b-form-radio>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="questionType.value === '2'">
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Multiple choice answers`)" class="required"
                          label-for="freeText"
                        >
                          <draggable
                            v-model="answersData"
                            tag="ul"
                            class="list-group cursor-move"
                            @end="updateAnswerOrder"
                          >
                            <b-list-group-item
                              v-for="(answer, index) in answersData"
                              :key="index"
                              tag="li"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="answer"
                                rules="required"
                              >
                                <div class="draggable-container d-flex">
                                  <b-form-input
                                    v-model="answersData[index]"
                                    :placeholder="i18nT(`Possible answer`)"
                                    name="answersData"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                  <div class="d-flex align-items-center">
                                    <feather-icon
                                      icon="Trash2Icon"
                                      size="17"
                                      class="cursor-pointer ml-1"
                                      @click="onDeleteAnswerClick(index)"
                                    />
                                  </div>
                                </div>
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-list-group-item>
                          </draggable>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            class="mt-1"
                            @click.prevent="onAddNewAnswerClick"
                          >
                            <feather-icon icon="PlusCircleIcon" />
                            {{i18nT(`Add new answer`)}}
                          </b-button>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" class="mt-1">
                        <b-form-group
                          label-for="otherOption"
                        >
                          <b-form-checkbox
                            name="otherOption"
                            id="otherOption"
                            switch
                            :checked="otherOption"
                            v-model="otherOption"
                          >
                          {{i18nT(`Include OTHER option and allow users to enter their own answer.`)}}
                        </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Select the number of possible answers`)"
                          label-for="freeText"
                        >
                          <div class="demo-inline-spacing mt-1">
                            <b-form-radio
                              v-model="possibleAnswers"
                              name="possibleAnswers"
                              value="1"
                              class="mt-0"
                            >
                              {{i18nT(`Allow one answer`)}}
                            </b-form-radio>
                            <b-form-radio
                              v-model="possibleAnswers"
                              name="possibleAnswers"
                              value="99"
                              class="mt-0"
                            >
                              {{i18nT(`Allow multiple answers`)}}
                            </b-form-radio>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="questionType.value === '3'">
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Please select the number of grading steps for the rating scale. You can select between 2,3,5 and 10 steps.`)"
                          label-for="freeText"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Steps`)"
                            rules="required"
                          >
                            <div>
                              <b-form-checkbox-group
                                v-model="steps"
                                :options="stepOptions"
                                buttons
                                button-variant="primary"
                                size="md"
                                name="steps"
                                :state="errors.length > 0 ? false:null"
                              />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Grading label options`)"
                          label-for="grading"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Grading label options`)"
                            rules="required"
                          >
                            <b-form-radio
                              v-if="steps === 2"
                              v-model="grading"
                              name="grading"
                              value="1"
                              class="mb-50"
                            >
                            {{ i18nT(`Emoji face`) }} 🙂 - 🙁
                            </b-form-radio>
                            <b-form-radio
                              v-model="grading"
                              name="grading"
                              value="2"
                              class="mb-50"
                            >
                            {{ i18nT(`Numbers (Ex. 1 to 10)`) }}
                            </b-form-radio>
                            <b-form-radio
                              v-model="grading"
                              name="grading"
                              value="3"
                              class="mb-50"
                            >
                            {{ i18nT(`Custom starting and ending label`) }}
                            </b-form-radio>
                            <b-form-radio
                              v-model="grading"
                              name="grading"
                              value="4"
                              class="mb-50"
                            >
                            {{ i18nT(`Custom labels`) }}
                            </b-form-radio>
                            <div>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="grading === '3'">
                      <b-col cols="6">
                        <b-form-group
                          :label="i18nT(`First step label`)" class="required"
                          label-for="firstStep"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`First step label`)"
                            rules="required"
                          >
                            <b-form-input
                              v-model="firstStep"
                              :placeholder="i18nT(`E.g. Question Name`)"
                              name="firstStep"
                              id="firstStep"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          :label="i18nT(`Last step label`)" class="required"
                          label-for="lastStep"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Last step label`)"
                            rules="required"
                          >
                            <b-form-input
                              v-model="lastStep"
                              :placeholder="i18nT(`E.g. Question Name`)"
                              name="lastStep"
                              id="lastStep"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="grading === '4'">
                      <b-col cols="12">
                        <b-form-group
                          v-for="i in steps"
                          :key="i"
                          ::label="i18nT(`'Custom label #'`) + i"
                          label-for="stepStrings"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`'Custom label #'`) + i"
                            rules="required"
                          >
                            <b-form-input
                              v-model="stepStrings[i - 1]"
                              :placeholder="i18nT(`E.g. Question Name`)"
                              :name="'stepStrings' + i"
                              :id="'stepStrings' + i"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <div style="flex: 1" />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-0 mt-md-2 ml-1 mr-1"
                    @click.prevent="onAddNewQuestionSubmit"
                  >
                  {{ i18nT(`Save`) }}
                  </b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  // BButtonGroup,
  // BAlert,
  // BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
  BCardBody,
  // BFormSelect,
  // BInputGroup,
  // BInputGroupText,
  BFormRadio,
  BListGroupItem,
  BCardHeader,
  BDropdown, 
  BDropdownItem
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import useAuth from "@/auth/useAuth";
import draggable from "vuedraggable";

export default {
  components: {
    BButton,
    // BButtonGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    vSelect,
    BFormTextarea,
    // BFormSelect,
    // BAlert,
    // BBadge,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    // BInputGroup,
    // BInputGroupText,
    BFormRadio,
    BListGroupItem,
    draggable,
    BCardHeader,
    BDropdown, 
    BDropdownItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      questionTypes: [],
      question: null,
      questionType: { value: "1" },
      questionLabel: "",
      questionDescription: "",
      answersData: [""],
      linesCnt: 1,
      possibleAnswers: 1,
      otherOption: false,
      steps: 0,
      stepOptions: [2, 3, 5, 10],
      stepStrings: [null, null, null, null, null, null, null, null, null, null],
      firstStep: "",
      lastStep: "",
      grading: 0,
    };
  },
  watch: {
    file: function (val) {
      if (val) {
        this.file = val;
        this.fileName = val.name;
      } else {
        this.file = null;
        this.fileName = "";
      }
    },
  },
  async created() {
    await this.$http.get(`surveys/questionTypes`).then(({ data }) => {
      const questionTypes = data.data;
      for (const property in questionTypes) {
        this.questionTypes.push({
          value: property,
          text: questionTypes[property],
        });
      }
    });

    const id = this.$route.params.id;
    if (id !== "new") {
      await this.$http.get(`surveys/questions?id=${id}`).then(({ data }) => {
        const question = data.data;
        console.log(question);
        this.questionType = this.questionTypes.find(
          (type) => type.value == question.Type
        );
        this.questionLabel = question.Label;
        this.questionDescription = question.Description;
        this.linesCnt = question.LinesCnt
          ? question.LinesCnt.toString()
          : question.LinesCnt;
        this.possibleAnswers = question.PossibleAnswers
          ? question.PossibleAnswers.toString()
          : question.PossibleAnswers;
        this.otherOption = question.OtherOption ? true : false;
        this.steps = question.Steps;
        this.firstStep = question.FirstStep;
        this.lastStep = question.LastStep;
        this.grading = question.Grading
          ? question.Grading.toString()
          : question.Grading;
        this.answersData = question.AnswersData.map((d) => d.Label);
        console.log(question);

        for (let i = 0; i < 10; i++) {
          this.stepStrings[i] = question["Step" + (i + 1)];
        }
      });
    }
  },
  computed: {
    hasRouteId() {
      return router && router.currentRoute.params.id
    }
  },
  methods: {
    onAddNewQuestionSubmit() {
      // Prevent modal from closing
      const id = this.$route.params.id;

      this.$refs.question
        .validate()
        .then((success) => {
          if (!success) return;
          // eslint-disable-next-line

          const formData = new FormData();
          if (id !== "new") formData.append("id", id);

          formData.append("Label", this.questionLabel);
          formData.append("Type", parseInt(this.questionType.value));
          formData.append("Description", this.questionDescription);
          if (this.questionType.value === "1") {
            formData.append("LinesCnt", parseInt(this.linesCnt));
          } else if (this.questionType.value === "2") {
            formData.append("PossibleAnswers", parseInt(this.possibleAnswers));
            formData.append("OtherOption", this.otherOption ? 1 : 0);
            this.answersData.forEach((d) => formData.append("AnswerData[]", d));
          } else {
            formData.append("Steps", parseInt(this.steps));
            formData.append("Grading", parseInt(this.grading));
            formData.append("FirstStep", this.firstStep);
            formData.append("LastStep", this.lastStep);

            for (let i = 0; i < 10; i++) {
              formData.append("Step" + (i + 1), this.stepStrings[i]);
            }
          }

          this.$http
            .post("surveys/questions", formData)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.successMessage(res),
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.errorMessage(err),
                  icon: "InfoIcon",
                  variant: "danger",
                },
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onAddNewQuestionClick() {
      this.$bvModal.show("modal-question");
    },
    onAddNewAnswerClick() {
      this.answersData.push("");
    },
    onDeleteAnswerClick(idx) {
      this.answersData.splice(idx, 1);
    },
    updateAnswerOrder() {
      console.log(this.answersData);
    },
    onFilePick: function () {
      this.$refs["filePicker"].$refs.input.click();
    },
    onFileDelete: function () {
      this.file = null;
      this.fileName = "";
    },
    getTypeName(typeId) {
      const questionType = this.questionTypes.find(
        (type) => type.value == typeId
      );
      return questionType ? questionType.text : "";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (
            !router.currentRoute.params.id &&
            !this.file &&
            !this.document.Url
          ) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.i18nT(`Missing file`),
                icon: "InfoIcon",
                variant: "danger",
              },
            });
            return;
          }

          // eslint-disable-next-line
          const formData = new FormData();
          formData.append("Label", this.document.Label);
          formData.append("Type", this.document.Type);
          formData.append("Description", this.document.Description);
          formData.append("AssignedTo", this.document.AssignedTo);

          if (this.file) {
            formData.append("fileUp", this.file);
          }

          formData.append("IsTemplate", this.document.Template ? 1 : 0);

          if (router.currentRoute.params.id) {
            formData.append("id", router.currentRoute.params.id);
          } else {
            const selectedSofware = useAuth.getCurrentSoftware();
            formData.append("Soft1", selectedSofware === "re" ? 1 : 0);
            formData.append("Soft2", selectedSofware === "on" ? 1 : 0);
            formData.append("Soft3", selectedSofware === "hr" ? 1 : 0);
            formData.append("Soft4", selectedSofware === "en" ? 1 : 0);
          }

          formData.append("Url", this.document.Url);
          this.$http
            .post("documents", formData)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.successMessage(res),
                  icon: "InfoIcon",
                  variant: "success",
                },
              });

              router.push({
                name:
                  router.currentRoute.meta.template === 1
                    ? "document-templates"
                    : "documents",
              });
            })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.errorMessage(err),
                  icon: "InfoIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    onSave() {
      console.log('Saved!!!')
    },
    onDelete() {
      this.$swal({
        title: 'Are you sure you want to delete this entry?',        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          console.log('Deleted!!!')
        }
      })
    }
  },
};
</script>

<style lang="scss">
.section-title {
  font-size: 11px;
}
.document-name {
  display: inline;
  line-height: 32px;
}
.document-actions-bar {
  float: right;
}
</style>
